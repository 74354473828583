<template>
  <div class="sticky top-0 inline-flex self-start flex-col items-center">
    <ul class="self-center">
      <li
        v-for="(toolbarItem, index) in toolbarItems"
        :key="index"
        class="p-3 border-t first:border-t-0 cursor-pointer group relative"
        @click="toolbarItem.callback"
      >
        <img
          :src="toolbarItem.iconUrl"
          :alt="toolbarItem.name"
          class="w-4 h-4 fill-current"
          width="16"
          height="16"
        />
        <div
          class="list-title-xxxs !font-medium absolute top-0 mt-3 ml-8 left-auto opacity-0 group-hover:opacity-50 pointer-events-none transition-all duration-200 whitespace-nowrap"
        >
          <span :class="isMm ? 'inline-block mt-2px' : ''">{{
            toolbarItem.tooltip
          }}</span>
        </div>
      </li>
      <li
        class="p-3 border-t first:border-t-0 cursor-pointer group relative"
        @click="addRemoveToReadingList"
        v-if="
          hasHydrated &&
          userStore.isLoggedIn &&
          userStore.user?.loginType !== 'IP' &&
          props.showBookmark
        "
      >
        <SaveIcon v-if="!saved" class="w-4 h-4 fill-current" />
        <SavedIcon v-if="saved" class="w-4 h-4 fill-current text-blue" />
        <div
          class="list-title-xxxs !font-medium absolute top-0 mt-3 ml-8 left-auto opacity-0 group-hover:opacity-50 pointer-events-none transition-all duration-200 whitespace-nowrap"
        >
          <span :class="isMm ? 'inline-block mt-2px' : ''">{{
            saved ? $t('Saved') : $t('Save')
          }}</span>
        </div>
      </li>
    </ul>
    <div
      class="rounded-full cursor-pointer bg-blue h-10 w-10 flex items-center justify-center mt-5 scale-0 transition-transform duration-200 transform"
      :class="{ 'scale-100': articleReaderActive }"
      @click="toggle"
    >
      <img
        v-if="articleReaderPlaying"
        class="w-4 h-4 inline-block"
        :src="pauseIconUrl"
      />
      <img v-else class="w-4 h-4 inline-block" :src="playIconUrl" />
    </div>
  </div>
</template>

<script setup lang="ts">
import facebookIconUrl from '~/assets/icons/facebook.svg?url'
import twitterIconUrl from '~/assets/icons/twitter.svg?url'
import linkedinIconUrl from '~/assets/icons/linkedin.svg?url'
import printIconUrl from '~/assets/icons/print.svg?url'
import pauseIconUrl from '~/assets/icons/pause.svg?url'
import playIconUrl from '~/assets/icons/play.svg?url'

import SaveIcon from '@/assets/icons/save.svg?component'
import SavedIcon from '@/assets/icons/saved.svg?component'
const { isMm } = useBrand()

const props = withDefaults(
  defineProps<{
    articleId: number
    showBookmark?: boolean
  }>(),
  {
    showBookmark: true,
  }
)

const articleReaderActive = ref(false)

const nuxtApp = useNuxtApp()
const userStore = useUserStore()
const indexStore = useIndexStore()
const hasHydrated = useHasHydrated()

type ToolbarItem = {
  name: string
  iconUrl: string
  callback: () => void
  tooltip: string
}

const toolbarItems = computed<ToolbarItem[]>(() => [
  {
    name: 'Facebook',
    iconUrl: facebookIconUrl,
    callback: facebook,
    tooltip: nuxtApp.$t('Share'),
  },
  {
    name: 'Twitter',
    iconUrl: twitterIconUrl,
    callback: twitter,
    tooltip: nuxtApp.$t('Share'),
  },
  {
    name: 'LinkedIn',
    iconUrl: linkedinIconUrl,
    callback: linkedin,
    tooltip: nuxtApp.$t('Share'),
  },
  {
    name: 'Print',
    iconUrl: printIconUrl,
    callback: print,
    tooltip: nuxtApp.$t('Print'),
  },
])

function facebook() {
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.href
    )}`,
    'facebook-share-dialog',
    'width=626,height=436'
  )
}

function twitter() {
  window.open(
    `https://twitter.com/share?url=${encodeURIComponent(
      window.location.href
    )}&text=${document.title}`,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
  )
}

function linkedin() {
  window.open(
    `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      window.location.href
    )}&title=${document.title}`,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
  )
}

function print() {
  window.print()
}

function toggle() {
  articleReaderPlaying.value = !articleReaderPlaying.value
}

const saved = computed(() => {
  if (userStore.user?.readingList) {
    for (let i = 0; i < userStore.user.readingList.length; i++) {
      const item = userStore.user.readingList[i]

      if (item.article?.id === props.articleId) {
        return true
      }
    }

    return false
  } else {
    return false
  }
})

const addRemoveToReadingList = async () => {
  if (userStore.user) {
    if (saved.value) {
      nuxtApp.$api.user.removeFromReadingList(
        userStore.user.userId,
        props.articleId
      )
    } else {
      await nuxtApp.$api.user.saveToReadingList(
        userStore.user.userId,
        props.articleId,
        indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1
      )
    }

    await userStore.refreshUser()
  }
}

const articleReaderPlaying = useArticleReaderPlaying()

watch(
  () => articleReaderPlaying.value,
  () => {
    articleReaderActive.value = true
  }
)
</script>

<style lang="scss" scoped>
.sticky {
  top: calc(50vh - 100px);
}
</style>
